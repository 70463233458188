
import { Vue, Component } from 'vue-property-decorator'
import TablePanel from '@/frame/share/table-panel.vue'
import _ from 'lodash'

import ImagesResourceApi, { ImagesResource } from '@/api/maintain/image-resource'
import { PagerQueryBean, PageListData } from '@/libs/http-request'
import { FormState } from '@/libs/form-state'
import { getShortUuid } from '@/libs/uuid'
import ExpendSearchPanel from '@/frame/share/expend-search-panel.vue'
import { getterUserInfo } from '@/store'
import EmptyPage from '@/components/empty-page.vue'
import ImageTypeSelector from '@/components/selector/image-type-selector.vue'

const defaultQuery = {
  page: 1,
  pageSize: 20,
  sorts: [],
  imageType: undefined
}

@Component({
  name: 'dataList',
  components: { TablePanel, ExpendSearchPanel, EmptyPage, ImageTypeSelector }
})
export default class DataPage extends Vue {
  loading = false; /// 查询数据
  queryData: PagerQueryBean = _.cloneDeep(defaultQuery);

  /**
   * 页面数据
   */
  pageData: PageListData<ImagesResource> = {
    items: [],
    total: 0,
    pageSize: 20
  };

  /// 总条数
  total = 20;

  // 监听路由，变化时刷新页面
  activated () {
    this.onPullDataListFromServer()
  }

  /// 当前页数改变
  handlePageChange (val: any) {
    this.onPullDataListFromServer()
  }

  /**
   * 当前编辑权限
   */
  get canEdit () {
    const user = getterUserInfo()
    return user.canOperateImageResource
  }

  /**
   * 打开表单对象
   * @param url 表单路径
   * @param mode 显示模式
   */
  openFormPage (name: string, id?: any, mode: string = FormState.View, tagName?: string) {
    // expect(id, '参数id不允许为空').not.undefined
    const route = {
      name: name,
      query: {
        query: getShortUuid()
      },
      params: {
        mode: mode,
        optionParam: id
      }
    }
    if (tagName) {
      (route.params as any).tagName = tagName
    }
    this.$router.push(route)
  }

  /// 拉取镜像资源列表
  onPullDataListFromServer () {
    ImagesResourceApi.query(this.queryData).then((res) => {
      this.pageData.items = res.data?.items || []
      this.pageData.pageSize = res.data?.pageSize || this.queryData.pageSize
      this.total = res.data?.total || 0
      if (this.total > 0) {
        this.$Message.success('查询成功')
      } else {
        this.$Message.warning('没有找到符合条件的镜像')
      }
    })
  }

  // 刷新数据
  refresh () {
    this.queryData.title = undefined;
    (this.$refs.imageTypeSelector as any).doCancel()
    this.onPullDataListFromServer()
  }
}
